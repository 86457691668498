import { IoIosArrowDown } from "react-icons/io";

export const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center gap-2 w-auto">
        Math <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "1-1",
        label: "Elementary Math (K-5)",
        path: "/math/elementary-math",
      },
      {
        key: "1-2",
        label: "Middle School Math (6-8)",
        path: "/math/middle-school-math",
      },
      {
        key: "1-3",
        label: "High School Math (9-12)",
        path: "/math/high-school-math",
      },
      {
        key: "1-4",
        label: "Common Core Math",
        path: "/math/common-core-math",
      },
      {
        key: "1-5",
        label: "Digital SAT Math",
        path: "/math/sat-math",
      },
      {
        key: "1-6",
        label: "ACT Math",
        path: "/math/act-math",
      },
    ],
  },
  {
    key: "2",
    label: (
      <div className="flex items-center gap-2">
        Coding <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "2-1",
        label: "PYTHON",
        path: "/coding/python",
      },
      {
        key: "2-2",
        label: "JAVA",
        path: "/coding/java",
      },
      {
        key: "2-3",
        label: "HTML, CSS , JAVASCRIPT",
        path: "/coding/html-web-development",
      },
      {
        key: "2-4",
        label: "APCSA/APCSP",
        path: "/coding/apcsa",
      },
      {
        key: "2-5",
        label: "SCRATCH",
        path: "/coding/scratch",
      },
      {
        key: "2-6",
        label: "ROBLOX",
        path: "/coding/roblox",
      },
    ],
  },
  // {
  //   key: "3",
  //   label: "Pricing",
  //   path: "/pricing",
  // },
  {
    key: "4",
    label: "Student Corner",
    path: "/student-corner",
  },
  {
    key: "5",
    label: "Book Trial",
    path: "/book-trial",
  },
  {
    key: "6",
    label: (
      <div className="flex items-center gap-2 ">
        Login <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "6-1",
        label: "Live Classes",
        path: "https://mlsclasses.onlineclass.site/login/",
        isExternal: true,
      },
      {
        key: "6-2",
        label: "Test Series",
        path: "https://testprep.mlsclasses.com/",
        isExternal: true,
      },
    ],
  },
];
